import React from 'react'
import { cx, css } from 'linaria'
import { graphql } from 'gatsby'

import { SessionBuddyApp } from '@components/contants'
import { Base } from '@ui/base'
import { Footer } from '@ui/footer'
import { Header } from '@ui/header'
import { CheckOn } from '@ui/check-on'
import { DownloadNow } from '@ui/download-now'

import banner from '@/images/session-buddy/banner.svg'
import boxPng from '@/images/session-buddy/box.png'
import { Jumbotron } from '@components/ui/jumbotron'
import { TheAccidentalCrash } from '@components/session-buddy/features/the-accidental-crash'
import demoVideo from '@/assets/video/demo-session-buddy.mp4'
import DemoOnMac from '@ui/demo-on-mac'
import { GetItBack } from '@components/session-buddy/features/get-it-back'
import { OneClickOnly } from '@components/session-buddy/features/one-click-only'
import { ModifyAsYouUse } from '@components/session-buddy/features/modify-as-you-use'
import { BootUpThePerformance } from '@components/session-buddy/features/boot-up-the-performance'
import Seo from '@components/seo-detail-page'

const SessionBuddyDetailsPage = ({ data, path }) => {
  const images = data.allScreenshots.edges.map(({ node }) => node.childImageSharp.fixed.src)
  return (
    <>
      <Seo {...SessionBuddyApp} video={demoVideo} images={images} />
      <Base>
        <Header className="top-0 sticky w-full z-50" />
        <Jumbotron
          {...SessionBuddyApp}
          bgClass={css`
            background: url(${banner}) no-repeat center center transparent;
            background-size: cover;
          `}
        />
        <TheAccidentalCrash />
        <div
          className={cx(
            'container px-4 relative z-10 md:pb-20 pb-10',
            css`
              margin-top: -20%;
            `
          )}
        >
          <DemoOnMac src={demoVideo} />
        </div>
        <GetItBack />
        <OneClickOnly />
        <ModifyAsYouUse />
        <BootUpThePerformance />
        <CheckOn {...SessionBuddyApp} />
        <DownloadNow className="bg-background-light" {...SessionBuddyApp} boxImage={boxPng} />
        <Footer path={path} />
      </Base>
    </>
  )
}

export const query = graphql`
  query {
    allScreenshots: allFile(filter: { absolutePath: { regex: "/session-buddy/screenshots/" } }) {
      edges {
        node {
          childImageSharp {
            fixed(jpegQuality: 100, fit: CONTAIN, width: 1200, height: 630) {
              src
            }
          }
        }
      }
    }
  }
`

export default SessionBuddyDetailsPage
