import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import { cx, css } from 'linaria'

export const OneClickOnly = () => {
  const data = useStaticQuery(graphql`
    query {
      desktopLogo: file(relativePath: { eq: "session-buddy/one-click-only.png" }) {
        childImageSharp {
          fluid(maxWidth: 430) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  const sources = [data?.desktopLogo?.childImageSharp.fluid]
  return (
    <div className="bg-background-light py-10 md:py-20">
      <div className="container px-4">
        <div className="flex justify-between flex-wrap md:flex-no-wrap items-center flex-row-reverse -mx-4">
          <div className="max-w-98 px-4 mb-5 md:mb-0">
            <h3 className="mb-4 text-section-title">
              Manage through <br />
              One-click only
            </h3>
            <p className="text-lg mb-4">
              Session manages the action of URLs through an extension icon in the menu bar.
            </p>
            <ul className="text-lg list-disc list-inside leading-loose">
              <li>Auto save when browser is closed</li>
              <li>Restore URL & groups through session name</li>
              <li>Add, delete and group URLs</li>
              <li>Import and export sessions</li>
            </ul>
          </div>
          <div
            className={cx(
              'w-full px-4',
              css`
                max-width: 462px;
              `
            )}
          >
            <Img
              fluid={sources}
              alt="Get it back in No time"
              className={cx(
                'w-full',
                css`
                  max-width: 430px;
                `
              )}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
