import React from 'react'
import { ReactComponent as SvgRocket } from './rocket.svg'

import { cx, css } from 'linaria'

export const BootUpThePerformance = () => {
  return (
    <section>
      <div
        className={cx(
          'max-w-6xl bg-secondary-black mx-auto py-10',
          css`
            @screen md {
              padding: 65px 0;
            }
          `
        )}
      >
        <div className="container px-4">
          <div className="flex flex-wrap md:flex-no-wrap items-center justify-between -mx-4">
            <div
              className={cx(
                'px-4',
                css`
                  max-width: 492px;
                  width: 100%;
                `
              )}
            >
              <SvgRocket className="w-full" />
            </div>
            <div
              className={cx(
                'px-4 text-white',
                css`
                  max-width: 25.75rem;
                  width: 100%;
                `
              )}
            >
              <h3 className="text-section-title mb-4">Boost up the Performance</h3>
              <div className="text-lg font-500 mb-4">
                Having less opened tabs drive more productivity. In several ways.
              </div>
              <ul className="text-lg">
                <li className="mb-4">
                  <b className="font-500">To the People:</b> Less opened tabs equals more job done. Having an endless
                  slide of tabs feds us up sometime. It sucks the energy out since we keep running around doing several
                  things at once.
                </li>
                <li>
                  <b className="font-500">To the System:</b> Having too many opening tabs makes RAM become overload.
                  With Session, users can get back to the previous tabs whenever they want, without keeping them in the
                  bar for hours.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
