import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

export const GetItBack = () => {
  const data = useStaticQuery(graphql`
    query {
      desktopLogo: file(relativePath: { eq: "session-buddy/get-it-back-in-no-time.png" }) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  const sources = [data?.desktopLogo?.childImageSharp.fluid]
  return (
    <div className="bg-white py-10 md:py-20">
      <div className="container px-4">
        <div className="flex justify-between flex-wrap items-center">
          <div className="max-w-90 mb-5 md:mb-0">
            <h3 className="text-section-title mb-4">Get it back in No time</h3>
            <p className="text-lg">
              Through a system of session management, Session Buddy allows user to restore the accidental-closed tabs,
              especially when they were opened and closed at the same time.
            </p>
          </div>
          <Img fluid={sources} alt="Get it back in No time" className="max-w-90 w-full" />
        </div>
      </div>
    </div>
  )
}
