import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

export const ModifyAsYouUse = () => {
  const data = useStaticQuery(graphql`
    query {
      desktopLogo: file(relativePath: { eq: "session-buddy/modify-as-you-use.png" }) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  const sources = [data?.desktopLogo?.childImageSharp.fluid]
  return (
    <div className="bg-white section">
      <div className="container px-4">
        <div className="flex justify-between flex-wrap items-center">
          <div className="max-w-90 mb-5 md:mb-0">
            <h3 className="mb-4 text-section-title">Modify as you use</h3>
            <p className="text-lg">
              In the case of research, all of your tabs might refer to one topic only. Best way to store things all at
              one and look it back conveniently? Give it a name.
            </p>
          </div>
          <Img fluid={sources} alt="Modify as you use" className="max-w-90 w-full" />
        </div>
      </div>
    </div>
  )
}
