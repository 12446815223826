import React from 'react'
import { cx, css } from 'linaria'
import bg from '@/images/session-buddy/bg-accidental-crash.png'

export const TheAccidentalCrash = () => {
  return (
    <div
      className={cx(
        'flex flex-col items-center text-secondary-black relative justify-center px-5',
        css`
          background: url(${bg}) repeat center center transparent;
          background-size: cover;
          padding-top: 40px;
          padding-bottom: 20%;

          @media (min-width: 768px) {
            padding-top: 120px;
          }
        `
      )}
    >
      <h2 className="text-center text-section-title mb-4 md:mb-6">The Accidental Crash</h2>
      <div
        className={cx(
          'w-full flex flex-col md:mb-20 mb-10',
          css`
            font-size: 18px;
            line-height: 1.8125rem;
            text-align: center;

            @media (min-width: 768px) {
              width: 80%;
              font-size: 21px;
            }
            @media (min-width: 1024px) {
              width: 760px;
            }
          `
        )}
      >
        <p className="mb-4">
          {`You're working on a research, and finally arrived to where you should be: 9876543 opening browsers - a perfect source of information.`}
        </p>
        <p className="mb-4">
          {`Things turn out great until you hit a button that somehow crashes down the whole thing. The notes, the emails, group chat, tutorial videos. Everything. And the most ironic part? "Restore Option" somehow doesn't even work.`}
        </p>
        <p className="block mb-4">
          <b className="text-xl md:text-2xl font-600">Goodbye thesis; hello internal shriek.</b>
        </p>
        <p>
          {`Chill. You're not the first person that went through this scene, and definitely won't be the last. Instead of raging the world, take a deep breath and let Session do its job.`}
        </p>
      </div>
    </div>
  )
}
